<template>
    <div id="bg">
      <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
        <el-table-column prop="id" label="活动ID" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="title" label="活动名称" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="活动类型" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <div v-if="scope.row.type == 1">新手福利</div>
            <div v-if="scope.row.type == 2">流水</div>
            <div v-if="scope.row.type == 3">累计充值</div>
            <div v-if="scope.row.type == 4">失利</div>
          </template>
        </el-table-column>
        <el-table-column prop="image" label="活动封面图" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <ximg :src="scope.row.image"  :width="30" :height="30" v-if="scope.row.image !== ''" alt=""></ximg>
        </template>
      </el-table-column>
        <el-table-column prop="introduction" label="活动简介" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="comment" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <div :style="{color: scope.row.status == 1 ? '#33C933' : '#F33333' }">{{scope.row.status == 0 ? '禁用' : '启用'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250px" align="center">
          <template #default="scope">
            <span class="operation" @click="editor(scope.row)">编辑</span>
            <span v-if="scope.row.type != 1" class="operation" @click="updateConfig(scope.row)">活动配置修改</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 修改活动配置弹窗 -->
      <el-dialog :title="configTitle" v-model="dialogConfig" width="800px" :close-on-click-modal="false" @close="closeDialog">
        <el-button @click="addDomain" size="small">新增</el-button>
        <div>
          <el-row :gutter="10">
            <el-col :span="7"><span class="title">达标金额</span></el-col>
            <el-col :span="7"><span class="title">赠送金额</span></el-col>
            <el-col :span="7"><span class="title">赠送的其它</span></el-col>
          </el-row>
        </div>
        <div class="extend" v-for="(value,key) in extend" :key="key">
          <el-row :gutter="10">
            <el-col :span="7">
              <el-input v-model="value.price" placeholder="达标金额" size="small" type="number"></el-input>
            </el-col>
            <el-col :span="7">
              <el-input v-model="value.gift_price" placeholder="赠送金额" size="small" type="number"></el-input>
            </el-col>
            <el-col :span="7">
                <el-input v-model="value.other" placeholder="赠送的其它" size="small"></el-input>
            </el-col>
            <el-col :span="3">
              <el-button type="danger" size="small" @click="removeDomain(key)">删除</el-button>
            </el-col>
          </el-row>
        </div>
        <template #footer>
          <span class="dialog-footer">
                      <el-input class="width_200" v-model="google_code" placeholder="请输入谷歌验证码" size="small"></el-input><el-button type="primary" @click="editConig">确认修改</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 修改活动弹窗 -->
      <el-dialog :title="title" v-model="dialogAdd" width="800px" :close-on-click-modal="false" @close="closeDialog">
        <el-form ref="dialogArr" :model="dialogArr" :rules="rules" label-width="100px">
          <el-form-item label="活动标题">
            <el-input v-model="dialogArr.title" placeholder="活动标题" size="small" />
          </el-form-item>
          <el-form-item label="活动权重">
            <el-input v-model="dialogArr.weight" placeholder="活动权重" size="small" />
          </el-form-item>
          <el-form-item label="活动封面图">
            <el-upload class="avatar-uploader" accept="image/jpeg,image/png" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
              <ximg v-if="dialogArr.imgUrl" :src="dialogArr.imgUrl" :enlarge="false" class="avatar" ></ximg>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="note">支持扩展名:.png .jpg .gif</div>
          </el-form-item>
          <el-form-item label="活动备注">
            <el-input v-model="dialogArr.comment" placeholder="活动备注" size="small" />
          </el-form-item>
          <el-form-item label="活动简介">
            <el-input v-model="dialogArr.introduction" type="textarea" placeholder="活动简介" size="small" />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="dialogArr.status" filterable placeholder="未选择" size="medium" clearable>
              <el-option key="0" label="禁用" value="0"></el-option>
              <el-option key="1" label="启用" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="谷歌验证码">
            <el-input v-model="google_code" placeholder="谷歌验证码" size="small"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="edit">确认修改</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { getList, edit, eventConfig } from "@/api/event/list";
  import { upload } from "@/api/common/common";
  export default {
    name: "eventList",
    data() {
      return {
        tableData: [], //数据
        row: [], //选中的数据
        title: "", //弹窗标题
        configTitle: "", // 活动配置标题
        dialogAdd: false,
        dialogConfig: false,
        extend:[{price: "",gift_price: "",other: ""}],
        dialogArr: {
          title: "", //标题
          weight: 0, //权重
          image: "", //图标地址
          imgUrl: "", //图标完整地址
          status: 0, // 活动状态：0=禁用，1=启用
          comment: "", // 活动备注
          introduction: "", // 活动简介
        },
        google_code:""
      };
    },
    computed: {},
    created() {},
    mounted() {
      this.getList();
    },
    methods: {
      //获取活动列表
      getList() {
        getList()
          .then((res) => {
            if (res.code == 0) {
              this.tableData = res.data;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      showBigImage(url){
        this.$common.showBigImages([{url:this.$store.getImage(url)}],0)
      },

      // 活动配置修改
      updateConfig(row){
        this.configTitle = `编辑活动配置(活动名称：${row.title})`;
        this.row = row;
        this.extend = []
        if(row.extend != ""){
          var attr = JSON.parse(row.extend)
          attr.forEach(val => {
            this.extend.push({
              price: val.price,
              gift_price: val.gift_price,
              other: val.other
            })
          });
        }else{
          this.extend = [{price: "",gift_price: "",other: ""}]
        }
        this.google_code = ""
        this.dialogConfig = true;
      },
      addDomain() {
        this.extend.push({price: "",gift_price: "",other: ""});
        console.log(this.extend);
      },
      removeDomain(index) {
      this.extend.splice(index, 1);
    },
      // 确认编辑活动配置
      editConig() {
        this.extend.forEach(item => {
          item.price = Number(item.price)
          item.gift_price = Number(item.gift_price)
        })
        // this.configArr.price = Number(this.configArr.price)
        // this.configArr.gift_price = Number(this.configArr.gift_price)
        // this.extend.push(this.configArr);
        eventConfig([
          { key: "id", val: this.row.id },
          { key: "extend", val: JSON.stringify(this.extend) },
          { key: "google_code", val: this.google_code },
        ]).then((res) => {
            if (res.code == 0) {
              this.getList();
              this.$message({ message: res.msg, type: "success" });
              this.dialogConfig = false;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      
      //把数据放入编辑内
      editor(row) {
        this.row = row;
        this.title = `编辑活动(ID：${row.id})`;
        this.dialogArr = {
          title: row.title, //标题
          weight: row.weight, //权重
          image: row.image, // 活动图片
          imgUrl: this.$store.getImage(row.image), // 图片完整地址
          status: row.status.toString(), // 活动状态
          comment: row.comment, // 活动备注
          introduction: row.introduction, // 活动简介
        };
        this.google_code = ""
        this.dialogAdd = true;
      },
      //编辑专题
      edit() {
        if (this.dialogArr.weight < 0)
          return this.$message({ message: "权重不能未负数", type: "error" });
        edit([
          { key: "id", val: this.row.id },
          { key: "title", val: this.dialogArr.title },
          { key: "weight", val: this.dialogArr.weight },
          { key: "comment", val: this.dialogArr.comment },
          { key: "introduction", val: this.dialogArr.introduction },
          { key: "google_code", val: this.google_code },
          { key: "image", val: this.dialogArr.image },
          { key: "status", val: this.dialogArr.status },
        ])
          .then((res) => {
            if (res.code == 0) {
              this.getList();
              this.$message({ message: res.msg, type: "success" });
              this.dialogAdd = false;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      
      //上传图片
      upload(param) {
        this.$message({ message: "上传中请稍后", type: "success" });
        upload({ file: param.file, name: "event" ,isencode:"true"})
          .then((res) => {
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.dialogArr.image = res.data.filename;
              this.dialogArr.imgUrl = res.data.path;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 图片上传前校验拦截
      beforeAvatarUpload(file) {
        const type = file.type;
        const isType = type !== "image/jpeg" ? type !== "image/png" ? type !== "image/gif" ? false : true : true : true;
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isType) {
          this.$message.error("上传图片格式错误!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过2MB!");
        }
        return isType && isLt2M;
      },
    },
  };
  </script>
  
  <style>
  .title {
    margin-left: 30%;
  }
  .extend {
    margin-bottom: 20px;
  }
  .el-input,
  .el-textarea {
    width: 100%;
  }
  .el-select .el-input {
    width: 100%;
  }
  /* 图片上传部分 */
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
  </style>